import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/component/seo"
import ContainerDemo from "../components/demo/demo"
import ContainerPrice from "../components/home/container-price"

export default () => (
  <Layout>
    <SEO title="Demo" />
    <ContainerDemo />
    <ContainerPrice />
  </Layout>
)
