import React from "react"
import Price from "../component/price"
import styled from "styled-components"
import imagePriceHome from "../../assets/images/Background_hero_home.jpg"
import imagePrice from "../../assets/images/Background_aplicaciones_adicionales.jpg"

const ContainerPrice = styled.div`
  h1 {
    margin: 20px auto;
    max-width: 800px;
    font-weight: bold;
    font-size: 34px;
    letter-spacing: 0px;
    color: #1b3055;
  }
  .title-online {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    letter-spacing: 0.7px;
  }
  .parrafe-price {
    margin-bottom: 80px;
    color: #576573;
    font-size: 18px;
  }
`

const ARRAY = [
  {
    id: 1,
    title: "FACTURACIÓN",
    month: "Desde / mes",
    price: "5mil",
    button: "Conoce los planes",
    primary: false,
    invert: undefined,
  },
  {
    id: 2,
    title: "NÓMINA",
    month: "Desde / mes",
    price: "15mil",
    button: "Conoce los planes",
    primary: true,
    invert: undefined,
  },
  {
    id: 3,
    title: "CONTADORES",
    month: "Desde / mes",
    price: "18mil",
    button: "Conoce los planes",
    primary: true,
    invert: undefined,
  },
  {
    id: 4,
    title: "EMPRESARIALES",
    month: "Desde / mes",
    price: "50mil",
    button: "Solicita un Demo",
    primary: true,
    invert: true,
  },
]

export default () => {
  return (
    <ContainerPrice>
      <div className="container flex flex-wrap pt-4 pb-10 m-auto mt-6 md:mt-15 lg:px-12 xl:px-16">
        <div className="w-full px-0 lg:px-4">
          <div className="text-center">
            <h2 className="title-online">PRECIOS</h2>
            <h1>Precios ajustados a tu negocio</h1>
            <p className="parrafe-price">Planes desde 5mil mensuales</p>
          </div>
          <div className="flex flex-wrap items-center justify-center py-4 pt-0">
            {ARRAY.map(value => (
              <div
                className="w-full p-4 md:w-1/2 lg:w-1/4 plan-card"
                key={value.id}
              >
                <Price data={value} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </ContainerPrice>
  )
}
