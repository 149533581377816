import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../component/link"
import styled from "styled-components"
import logo from "../../assets/images/logo.svg"
import imageHero from "../../assets/images/image_hero_home.png"

const ContainerCongratulations = styled.div`
  padding: 60px 215px;
  .congratulations {
    text-align: center;
    max-width: 695px;
    h1 {
      color: #1b3055;
      font-size: 44px;
      font-weight: bold;
    }
    h4 {
      color: #576573;
      font-size: 22px;
      line-height: 35px;
      font-weight: 400;
    }
  }
  .img-congratulations img  {
    width: 100%;
  }
  @media (max-width: 480px) {
    padding: 20px;
  }
`
export default () => {
  return (
    <ContainerCongratulations>
      <Link to="/" className="navbar-brand">
        <img src={logo} alt="Logo clausErp" width="154px" height="43px" />
      </Link>
      <div className="congratulations">
        <div>
          <div>
            <h1>Gracias por ser parte de nuestro equipo.</h1>
            <h4>
              En breve recibirás mayor información de cómo implementar tu
              contabilidad de manera automática
            </h4>
            <div className="img-congratulations pt-4">
              <img src={imageHero} />
            </div>
          </div>
        </div>
      </div>
    </ContainerCongratulations>
  )
}
