import React from "react"
import Styled from "styled-components"

let Hr = Styled.hr`
  width: 100%;
  border-width: 1px;
  background: #27467C;
`

export default () => <Hr />
