import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import imageCharacteristics from "../../assets/images/img_caracteristicas.png"
import loco1 from "../../assets/images/characteristics/icon_centro_de_costos.svg"
import loco2 from "../../assets/images/characteristics/icon_conciliacion_bancaria.svg"
import loco3 from "../../assets/images/characteristics/icon_Facturacion_electronica.svg"
import loco4 from "../../assets/images/characteristics/icon_impuetos.svg"
import loco5 from "../../assets/images/characteristics/icon_inventarios_automaticos.svg"
import loco6 from "../../assets/images/characteristics/icon_nomina_automatica.svg"

const ARRAY = [
  {
    key: 1,
    img: loco1,
    title: "Facturación electrónica",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 2,
    img: loco2,
    title: "Conciliación bancaria",
    subTitle: "Automática y precisa.",
    link: "",
  },
  {
    key: 3,
    img: loco3,
    title: "Impuestos",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 4,
    img: loco4,
    title: "Centros de costos",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 5,
    img: loco5,
    title: "Nómina automática",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 6,
    img: loco6,
    title: "Inventarios automáticos",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
]

const ContainerCharacteristics = styled.div`
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    color: #191851;
    font-size: 44px;
    font-weight: bold;
    line-height: 60px;
  }
  img {
    max-width: 100%;
    width: 100%;
  }
  .container-characteristics {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0078bb33;
    border-radius: 50px;
    opacity: 1;
    width: 76px !important;
  }
  .characteristics-list {
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .characteristics-subtitle {
    font-size: 16px;
    font-weight: normal;
    color: #576573;
    margin-bottom: 40px;
  }
  .characteristics-link {
    font-size: 16px;
    color: #1465ff;
    font-weight: bold;
  }
  @media (max-width: 480px) {
    h3 {
      font-size: 14px;
    }
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
  }
`
export default () => {
  return (
    <ContainerCharacteristics className="py-28">
      <div className="container m-auto">
        <div className="lg:flex justify-between items-center">
          <div className="lg:w-6/12 lg:p-0 p-7">
            <h3 className="text-left">CARÁCTERISTICAS</h3>
            <h1 className="mt-4 text-left">
              ¿Por qué importa lo que hacemos, qué emociones genera?
            </h1>
            <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-3 mt-12">
              {ARRAY.map(({ key, img, title, subTitle, link }) => (
                <div key={key}>
                  <img className="container-characteristics" src={img} />
                  <div className="text">
                    <p className="characteristics-list">{title}</p>
                    <p className="characteristics-subtitle">{subTitle}</p>
                    <a className="characteristics-link">Ver más +</a>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-5/12 order-2">
            <img src={imageCharacteristics} alt="" />
          </div>
        </div>
      </div>
    </ContainerCharacteristics>
  )
}
