import React from "react"
import Link from "../component/link"

const MenuPrice = props => {
  return (
    <div className="border-top-menu absolute z-10 transform mt-3 px-2 w-screen max-w-md sm:px-0">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          {props.data &&
            props.data.map(({ url, name }, i) => {
              return (
                <Link
                  key={i}
                  to={url}
                  className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                >
                  <div className="ml-4">
                    <p className="text-base font-medium text-gray-900">
                      {name}
                    </p>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
    </div>
  )
}
export default MenuPrice
