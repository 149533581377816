import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import PriceCard from "../component/price-single"
import imageReferreals from "../../assets/images/referreals-home.png"

const ContainerReferreals = styled.div`
  padding: 60px 215px;
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    max-width: 480px;
    color: #191851;
    font-size: 34px;
    font-weight: bold;
    line-height: 60px;
  }
  h4 {
    max-width: 455px;
    color: #576573;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
  }
  .link-blue {
    color: #1465ff;
    font-size: 16px;
    font-weight: 500;
  }
  .container-referreals-image {
    background-image: url(${imageReferreals});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const VALUE = {
  id: 1,
  title: "FACTURACIÓN",
  month: "Por empresa/mes*",
  price: "5mil",
  button: "Registrarme",
  primary: false,
  invert: undefined,
  titleCard: "Tipos de facturación",
  list: ["Electrónica DIAN", "Por computador", "POS", "Preimpresa"],
}

export default () => {
  return (
    <ContainerReferreals>
      <div>
        <div>
          <div>
            <h3>REFERIDOS PARA CONTADORES</h3>
            <h1>
              Crece inscribiéndote a nuestros planes de referidos para
              contadores
            </h1>
            <h4>
              Has parte de nuestro directorio VIP de contadores que usan Claus y
              también gana planes contador GRATIS por referirnos.
            </h4>
            <div className="link-blue">Ver más</div>
          </div>
          <div>
            <div className="container-referreals-image">
              <PriceCard data={VALUE} />
            </div>
          </div>
        </div>
      </div>
    </ContainerReferreals>
  )
}
