import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../component/link"
import styled from "styled-components"
import Button from "../component/btn"

const ContainerDemo = styled.div`
  background-color: #f8f8f8;
  h1 {
    color: #1b3055;
    font-size: 34px;
    font-weight: bold;
  }
  .subTitle {
    color: #576573;
    font-size: 18px;
    max-width: 640px;
    margin: 26px auto;
  }
  .author {
    color: #1b3055;
    font-weight: bold;
  }
`
export default () => {
  return (
    <ContainerDemo>
      <div className="w-full max-w-7xl mx-auto">
        <div className="w-full md:text-center px-4 pt-6 md:py-6">
          <h1 className="mt-40">Empieza ahora a usar Clauserp</h1>
          <p className="subTitle">
            conoce lo fácil que es disminuir tu tiempo de trabajo con un
            software contable en la nube
          </p>
        </div>
        <div className="flex flex-wrap -mx-1 lg:-mx-4">
          <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-2/4">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a href="#">
                <img
                  alt="Placeholder"
                  className="block h-auto w-full"
                  src="https://picsum.photos/600/400/?random"
                />
              </a>

              <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                <div
                  className="w-full flex items-center justify-between"
                  href="#"
                >
                  <p className="ml-2 text-2xl author">
                    Facturación electrónica
                  </p>
                  <Button
                    to="https://github.com/jeremylynch/gatsby-strapi-starter"
                    className="btn btn-primary btn-lg"
                    styled={{ width: "100%" }}
                  >
                    Solicitar Demo
                  </Button>
                </div>
                <a
                  className="no-underline text-grey-darker hover:text-red-dark"
                  href="#"
                >
                  <span className="hidden">Like</span>
                  <i className="fa fa-heart"></i>
                </a>
              </footer>
            </article>
          </div>

          <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-2/4">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a href="#">
                <img
                  alt="Placeholder"
                  className="block h-auto w-full"
                  src="https://picsum.photos/600/400/?random"
                />
              </a>

              <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                <div
                  className="w-full flex items-center justify-between"
                  href="#"
                >
                  <p className="ml-2 text-2xl author">Software nómina</p>
                  <Button
                    to="https://github.com/jeremylynch/gatsby-strapi-starter"
                    className="btn btn-primary btn-lg"
                    styled={{ width: "100%" }}
                  >
                    Solicitar Demo
                  </Button>
                </div>
                <a
                  className="no-underline text-grey-darker hover:text-red-dark"
                  href="#"
                >
                  <span className="hidden">Like</span>
                  <i className="fa fa-heart"></i>
                </a>
              </footer>
            </article>
          </div>

          <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-2/4">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a href="#">
                <img
                  alt="Placeholder"
                  className="block h-auto w-full"
                  src="https://picsum.photos/600/400/?random"
                />
              </a>

              <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                <div
                  className="w-full flex items-center justify-between"
                  href="#"
                >
                  <p className="ml-2 text-2xl author">Software contable</p>
                  <Button
                    to="https://github.com/jeremylynch/gatsby-strapi-starter"
                    className="btn btn-primary btn-lg"
                    styled={{ width: "100%" }}
                  >
                    Solicitar Demo
                  </Button>
                </div>
                <a
                  className="no-underline text-grey-darker hover:text-red-dark"
                  href="#"
                >
                  <span className="hidden">Like</span>
                  <i className="fa fa-heart"></i>
                </a>
              </footer>
            </article>
          </div>

          <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-2/4">
            <article className="overflow-hidden rounded-lg shadow-lg">
              <a href="#">
                <img
                  alt="Placeholder"
                  className="block h-auto w-full"
                  src="https://picsum.photos/600/400/?random"
                />
              </a>

              <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                <div
                  className="w-full flex items-center justify-between"
                  href="#"
                >
                  <p className="ml-2 text-2xl author">Software inventario</p>
                  <Button
                    to="https://github.com/jeremylynch/gatsby-strapi-starter"
                    className="btn btn-primary btn-lg"
                    styled={{ width: "100%" }}
                  >
                    Solicitar Demo
                  </Button>
                </div>
                <a
                  className="no-underline text-grey-darker hover:text-red-dark"
                  href="#"
                >
                  <span className="hidden">Like</span>
                  <i className="fa fa-heart"></i>
                </a>
              </footer>
            </article>
          </div>
        </div>
      </div>
    </ContainerDemo>
  )
}
