import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/component/seo"
import ContainerPrices from "../components/prices/container-prices"
import ContainerPlan from "../components/prices/container-plan"
import ContainerPackage from "../components/prices/container-package"

export default props => {
  return (
    <Layout>
      <SEO title="Price" />
      <ContainerPrices {...props} />
      <ContainerPlan />
      <ContainerPackage />
    </Layout>
  )
}
