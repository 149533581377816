import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Link from "../component/link"
import styled from "styled-components"
import Button from "../component/btn"

const ContainerPostBlog = styled.div`
  background-color: #f8f8f8;
  .bg-primary-color-grayish-blue {
    background: hsl(217, 19%, 35%);
  }
  .bg-primary-color-blue {
    background: hsl(217, 90%, 31%);
  }
  .bg-primary-color-white {
    background: hsl(0, 0%, 100%);
  }
  .primary-color-blackish-blue {
    color: rgb(25, 33, 46);
  }
  .primary-color-blackish-blue-opacity {
    color: rgba(25, 33, 46, 0.5);
  }
  .color-neutral-light-gray {
    color: hsl(0, 0%, 81%);
  }
  .color-neutral-grayish-blue {
    color: hsl(210, 46%, 95%);
  }
  h1 {
    font-weight: 600;
  }
  h2,
  p {
    font-weight: 500;
  }
`
export default () => {
  return (
    <ContainerPostBlog>
      <div className="w-full max-w-7xl mx-auto">
        <div className="mx-10 pt-15">
          <div className="grid gap-6 mb-20 md:grid-cols-2">
            <div className="mt-40 min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs">
              <h4 className="mb-4 font-semibold">Revenue</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga,
                cum commodi a omnis numquam quod? Totam exercitationem quos hic
                ipsam at qui cum numquam, sed amet ratione! Ratione, nihil
                dolorum.
              </p>
            </div>
            <div className="mt-40 min-w-0 p-4 text-white bg-purple-600 rounded-lg shadow-xs">
              <h4 className="mb-4 font-semibold">Colored card</h4>
              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fuga,
                cum commodi a omnis numquam quod? Totam exercitationem quos hic
                ipsam at qui cum numquam, sed amet ratione! Ratione, nihil
                dolorum.
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 mb-16">
          <div class="md:p-8 p-2 bg-white rounded-lg shadow-xs">
            <img
              class="rounded-lg w-full"
              src="https://images.unsplash.com/photo-1603349206295-dde20617cb6a?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80
          "
            />
            <p class="text-indigo-500 font-semibold text-base mt-2">Science</p>
            <h1 class="font-semibold text-gray-900 leading-none text-xl mt-1 capitalize truncate">
              the life of albert einstein
            </h1>
            <div class="max-w-full">
              <p class="text-base font-medium tracking-wide text-gray-600 mt-1">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Obcaecati vel soluta dolore id nesciunt eum nam ipsam, eveniet
                cupiditate sint veritatis harum odit. Iste dignissimos, ad
                provident nulla voluptatum ut.
              </p>
            </div>
            <div class="flex items-center space-x-2 mt-20">
              <img
                class="w-10 h-10 object-cover object-center rounded-full"
                src="https://randomuser.me/api/portraits/men/54.jpg"
                alt="random user"
              />
              <div>
                <p class="text-gray-900 font-semibold">Lugano Shabani</p>
                <p class="text-gray-500 font-semibold text-sm">
                  Feb 24,2021 &middot; 6 min read
                </p>
              </div>
            </div>
          </div>

          <div class="p-8 bg-white rounded-lg shadow-xs">
            <img
              class="rounded-lg w-full"
              src="https://images.unsplash.com/photo-1611162618071-b39a2ec055fb?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80
          "
            />
            <p class="text-indigo-500 font-semibold text-base mt-2">
              Startup stories
            </p>
            <h1 class="font-semibold text-gray-900 leading-none text-xl mt-1 capitalize truncate">
              The rise of facebook
            </h1>
            <div class="max-w-full">
              <p class="text-base font-medium tracking-wide text-gray-600 mt-1">
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nihil,
                dignissimos repudiandae. Consequuntur minus ipsam repudiandae
                soluta qui, recusandae obcaecati molestias commodi magnam nisi
                illo illum quaerat aut maiores! Esse, aperiam!
              </p>
            </div>
            <div class="flex items-center space-x-2 mt-20">
              <img
                class="w-10 h-10 object-cover object-center rounded-full"
                src="https://randomuser.me/api/portraits/men/54.jpg"
                alt="random user"
              />
              <div>
                <p class="text-gray-900 font-semibold">Lugano Shabani</p>
                <p class="text-gray-500 font-semibold text-sm">
                  Feb 24,2021 &middot; 6 min read
                </p>
              </div>
            </div>
          </div>

          <div class="p-8 bg-white rounded-lg shadow-xs">
            <img
              class="h-80 rounded-lg w-full object-cover object-center"
              src="
        https://images.unsplash.com/photo-1580867532901-7e3707f178ce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=732&q=80"
            />
            <p class="text-indigo-500 font-semibold text-base mt-2">Culture</p>
            <h1 class="font-semibold text-gray-900 leading-none text-xl mt-1 capitalize truncate">
              the life of masaai people from tanzania
            </h1>
            <div class="max-w-full">
              <p class="text-base font-medium tracking-wide text-gray-600 mt-1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                officiis aspernatur, modi nobis et neque quod asperiores
                laboriosam ab. Magni fugit necessitatibus ducimus placeat
                assumenda perferendis laborum quae aperiam minus.
              </p>
            </div>
            <div class="flex items-center space-x-2 mt-20">
              <img
                class="w-10 h-10 object-cover object-center rounded-full"
                src="https://randomuser.me/api/portraits/men/54.jpg"
                alt="random user"
              />
              <div>
                <p class="text-gray-900 font-semibold">Lugano Shabani</p>
                <p class="text-gray-500 font-semibold text-sm">
                  Feb 24,2021 &middot; 6 min read
                </p>
              </div>
            </div>
          </div>

          <div class="p-8 bg-white rounded-lg shadow-xs">
            <img
              class="h-80 rounded-lg w-full object-cover object-center"
              src="
        https://images.unsplash.com/photo-1580867532901-7e3707f178ce?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=732&q=80"
            />
            <p class="text-indigo-500 font-semibold text-base mt-2">Culture</p>
            <h1 class="font-semibold text-gray-900 leading-none text-xl mt-1 capitalize truncate">
              the life of masaai people from tanzania
            </h1>
            <div class="max-w-full">
              <p class="text-base font-medium tracking-wide text-gray-600 mt-1">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus
                officiis aspernatur, modi nobis et neque quod asperiores
                laboriosam ab. Magni fugit necessitatibus ducimus placeat
                assumenda perferendis laborum quae aperiam minus.
              </p>
            </div>
            <div class="flex items-center space-x-2 mt-20">
              <img
                class="w-10 h-10 object-cover object-center rounded-full"
                src="https://randomuser.me/api/portraits/men/54.jpg"
                alt="random user"
              />
              <div>
                <p class="text-gray-900 font-semibold">Lugano Shabani</p>
                <p class="text-gray-500 font-semibold text-sm">
                  Feb 24,2021 &middot; 6 min read
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-2">
          <nav className="block mb-16">
            <ul className="flex pl-0 rounded list-none flex-wrap">
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  <i className="fas fa-chevron-left -ml-px"></i>
                  <i className="fas fa-chevron-left -ml-px"></i>
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  <i className="fas fa-chevron-left -ml-px"></i>
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 text-white bg-indigo-500"
                >
                  1
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  2
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  3
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  4
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  5
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  <i className="fas fa-chevron-right -mr-px"></i>
                </a>
              </li>
              <li>
                <a
                  href="#pablo"
                  className="first:ml-0 text-xs font-semibold flex w-8 h-8 mx-1 p-0 rounded-full items-center justify-center leading-tight relative border border-solid border-indigo-500 bg-white text-indigo-500"
                >
                  <i className="fas fa-chevron-right -mr-px"></i>
                  <i className="fas fa-chevron-right -mr-px"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </ContainerPostBlog>
  )
}
