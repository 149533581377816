import React from "react"
import imgTestimonial from "../../assets/images/clauserpaws.png"

export default () => {
  return (
    <div className="max-w-full w-full my-28">
      <img src={imgTestimonial} />
    </div>
  )
}
