import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/component/seo"
import ContainerPostBlog from "../components/blog/post-blog"

export default () => (
  <Layout>
    <SEO title="Post Blog" />
    <ContainerPostBlog />
  </Layout>
)
