import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Button from "../component/btn"
import imageHero from "../../assets/images/image_hero_home.png"

const ContainerHome = styled.div`
  .imge-home {
    background-position: center right;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
  }
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    max-width: 470px;
    color: #191851;
    font-size: 44px;
    font-weight: bold;
    line-height: 60px;
  }
  h4 {
    max-width: 455px;
    color: #576573;
    font-size: 20px;
    line-height: 35px;
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
  @media (max-width: 480px) {
    .button-home {
      width: 100%;
    }
  }
`
export default () => {
  const sideMetadata = useStaticQuery(graphql`
    query TitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  let { title, author } = sideMetadata.site.siteMetadata
  return (
    <ContainerHome>
      <div className="flex flex-wrap">
        <div className="w-full mt-20 md:w-1/2 flex flex-col justify-center items-center">
          <h3 className="py-4">completamente online</h3>
          <h1 className="py-4">
            Toda la contabilidad de tu empresa en un solo lugar
          </h1>
          <h4 className="py-4">
            !NO¡ más papeleo, tén acceso a nuestro software desde cualquier
            lugar del mundo.
          </h4>
          <div className="button-home py-4 grid sm:grid-cols-1 md:grid-cols-2 md:gap-12 lg:gap-4">
            <Button
              to="https://github.com/jeremylynch/gatsby-strapi-starter"
              className="btn btn-primary btn-lg"
              primary={true}
            >
              Prueba gratis
            </Button>
            <Button
              to="https://github.com/jeremylynch/gatsby-strapi-starter"
              className="btn btn-primary btn-lg"
            >
              Ver demo
            </Button>
          </div>
        </div>
        <div className="w-full mt-20 md:w-1/2">
          <img src={imageHero} className="object-cover object-right w-full" />
        </div>
      </div>
    </ContainerHome>
  )
}
