import React from "react"
import Button from "./btn"
import styled from "styled-components"

const ContainerForm = styled.form`
  .button-submit {
    margin-top: 20px;
    width: 100%;
  }
  .check-condition {
    color: #576573;
    margin: 0 10px;
  }
  label {
    margin-bottom: 20px;
    font-size: 14px;
    color: #1b3055;
  }
  input  {
    padding: 25px;
    border: 1px solid #07ade459;
    border-radius: 5px;
    opacity: 1;

    &::placeholder {
      color: #576573;
    }
  }
  input[type="checkbox"] {
    width: 18px;
    height: 21px;
    color: #576573;
    border: 2px solid #576573;
    opacity: 0.5;
  }
`

export default props => (
  <ContainerForm className="max-w-xl">
    <div className="flex -mx-3">
      <div className="w-full px-3 mb-5">
        <label for="" className="text-xs font-semibold px-1">
          Nombre y apellidos
        </label>
        <div className="flex">
          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
            <i className="mdi mdi-account-outline text-gray-400 text-lg"></i>
          </div>
          <input
            type="text"
            name="text"
            id="registerName"
            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
            placeholder="Nombre y apellidos"
          />
        </div>
      </div>
    </div>
    <div className="flex -mx-3">
      <div className="w-full px-3 mb-5">
        <label for="" className="text-xs font-semibold px-1">
          Correo electrónico
        </label>
        <div className="flex">
          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
            <i className="mdi mdi-email-outline text-gray-400 text-lg"></i>
          </div>
          <input
            type="email"
            name="email"
            id="registerEmail"
            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
            placeholder="Correo electrónico"
          />
        </div>
      </div>
    </div>
    <div className="flex -mx-3">
      <div className="w-full px-3 mb-12">
        <label for="" className="text-xs font-semibold px-1">
          Teléfono celular
        </label>
        <div className="flex">
          <div className="w-10 z-10 pl-1 text-center pointer-events-none flex items-center justify-center">
            <i className="mdi mdi-lock-outline text-gray-400 text-lg"></i>
          </div>
          <input
            type="text"
            name="phone"
            id="registerPhone"
            className="w-full -ml-10 pl-10 pr-3 py-2 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
            placeholder="Teléfono celular"
          />
        </div>
      </div>
    </div>
    <div className="flex -mx-3">
      <div className="w-full px-3 mb-12">
        <div className="flex">
          <input type="checkbox" name="check" id="registerCheck" />
          <label className="check-condition" for="registerCheck" check>
            *Acepto expresamente la Autorización de Datos Personales de Software
            de Colombia S.A.S. y la Política de Privacidad.
          </label>
        </div>
      </div>
    </div>
    <div className="flex -mx-3">
      <div className="w-full px-3 mb-5">
        <Button
          className="block w-full text-white rounded-lg px-3 py-3 button-submit"
          primary={true}
        >
          Enviar
        </Button>
      </div>
    </div>
  </ContainerForm>
)
