import React from "react"
import Link from "../component/link"
import HR from "../component/hr"
import styled from "styled-components"
import logo from "../../assets/images/logo_claus_white.svg"
import twitter from "../../assets/images/logo_twitter.svg"
import facebook from "../../assets/images/logo_facebook.svg"
import youtube from "../../assets/images/logo_youtube.svg"
import linkedin from "../../assets/images/logo_linkedin.svg"
import phone from "../../assets/images/phone.svg"

const Footer = styled.footer`
  background: #191851;
  color: white;
  font-size: 1rem;
  ul {
    color: white;
    padding: 0;
    font-size: 16px;
    & > li {
      list-style: none;
      a,
      a:hover {
        color: #d0dcf0;
        font-size: 14px;
        font-weight: normal;
        text-decoration: none;
      }
    }
  }
  p {
    margin: 0 5px;
  }
  .container-telephone {
    font-size: 12px;
  }
  .telephone-title {
    font-size: 16px;
    color: #ffffffb3;
  }
  .title-country {
    font-size: 1rem;
  }
  .title-term-condition {
    font-size: 12px;
    color: #b1c5e7;
  }
  .title-copy-right {
    color: white;
    font-size: 11px;
  }
  .phone {
    margin: 5px 20px 0 0;
  }
`

let SocialLink = ({ Icon }) => (
  <Link to="/" className="mr-2">
    <img src={Icon} alt="" />
  </Link>
)

let FooterLink = ({ to, children, className }) => (
  <li className={className}>
    <Link to={to}>{children}</Link>
  </li>
)

export default () => (
  <Footer className="pt-10 sm:mt-10">
    <div className="max-w-screen-2xl m-auto flex flex-wrap justify-center">
      <div className="p-5 w-1/2 sm:w-4/12 md:w-2/12">
        <h5>
          <SocialLink Icon={logo} />
        </h5>
        <ul>
          <FooterLink to="/" className="my-10">
            <img className="phone" align="left" src={phone} />
            <p className="container-telephone">Telefono</p>
            <span className="telephone-title">+57 349 345 4678</span>
          </FooterLink>
          <div className="my-16 flex">
            <SocialLink className="flex-1" Icon={twitter} />
            <SocialLink className="flex-1" Icon={facebook} />
            <SocialLink className="flex-1" Icon={youtube} />
            <SocialLink className="flex-1" Icon={linkedin} />
          </div>
        </ul>
      </div>

      <div className="p-5 w-1/2 sm:w-4/12 md:w-2/12">
        <div className="text-base font-medium">Productos</div>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300">
          Software contable
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Otro título
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Otro título
        </FooterLink>
      </div>

      <div className="p-5 w-1/2 sm:w-4/12 md:w-2/12">
        <div className="text-base font-medium">Recursos</div>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Blog
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Formatos de excel
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Centro de ayuda
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Videos
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          E-books
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Webbinars
        </FooterLink>
      </div>
      <div className="p-5 w-1/2 sm:w-4/12 md:w-2/12">
        <div className="text-base font-medium">Aliados</div>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Referidos para Contadores
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Referidos para clientes
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Referidos para personas externas
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Otros aliados
        </FooterLink>
      </div>
      <div className="p-5 w-1/2 sm:w-4/12 md:w-2/12">
        <div className="text-base font-medium">Precios</div>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Empresariales
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Facturación electrónica
        </FooterLink>
        <FooterLink to="/" className="my-3 block text-sm text-gray-300	">
          Nómina
        </FooterLink>
      </div>
    </div>

    <div className="pt-2 border-t border-blue-800">
      <div
        className="flex pb-5 px-3 m-auto pt-5 text-sm flex-col
      md:flex-row max-w-6xl"
      >
        <div className="flex-1">
          <span className="title-country">Colombia</span>
        </div>
        <div className="flex-1">
          <span className="title-term-condition">
            Términos y condiciones de uso
          </span>
        </div>
        <div className="flex-1">
          <span className="title-term-condition">Política de privacidad</span>
        </div>
        <div className="md:flex-auto md:flex-row-reverse mt-2 flex-row flex"></div>
        <div className="title-copy-right">
          © Clauserp. Todos los Derechos Reservados
        </div>
      </div>
    </div>
  </Footer>
)
