import React from "react"
import Link from "../components/component/link"
import Layout from "../components/layout/layout"
import ContactForm from "../components/component/form"
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaFax } from "react-icons/fa"
import Styled from "styled-components"
import Hr from "../components/component/hr"
import PageTitle from "../components/component/page-title"
import { graphql } from "gatsby"

let Cirle = Styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

let ContactItem = ({ text, type, Icon, href }) => (
  <div
  >
    <Cirle className="">
      <Icon size={30} />
    </Cirle>
    <div className="text-center">
      <h6>{type}</h6>
      <span className="text-secondary">
        {href ? (
          <Link to={href} className="reset">
            {text}
          </Link>
        ) : (
          text
        )}
      </span>
    </div>
  </div>
)

export default ({ data }) => {
  let { address, email, phone, fax } = data.site.siteMetadata
  return (
    <Layout>
      <PageTitle title="Contact Us" />
      <div>
        <div>
          <ContactItem text={address} type="Address" Icon={FaMapMarkerAlt} />
          <ContactItem
            text={email}
            href={`mailto:${email}`}
            type="Email"
            Icon={FaEnvelope}
          />
          <ContactItem
            text={phone}
            href={`tel:${phone}`}
            type="Phone"
            Icon={FaPhone}
          />
          <ContactItem text={fax} type="Fax" Icon={FaFax} />
        </div>
        <hr />
        <div>
          <div>
            <h4 >Message Us</h4>
            <Hr />
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Contact {
    site {
      siteMetadata {
        address
        email
        phone
        fax
      }
    }
  }
`
