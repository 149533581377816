import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Button from "../component/btn"
import imagePackage from "../../assets/images/completa_tu_paquete.png"

const ContainerPackage = styled.div`
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    color: #191851;
    font-size: 34px;
    font-weight: bold;
    line-height: 60px;
  }
  h4 {
    color: #576573;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }
  ul,
  li {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  ul {
    margin: 2em 0;
  }
  li {
    margin: 1em;
    margin-left: 3em;
  }
  li:before {
    content: "+";
    float: left;
    margin-left: -2em;
    color: #1465ff;
    background: #00deff;
    padding: 0px 5px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }
`
export default () => {
  return (
    <ContainerPackage>
      <div className="max-w-full w-full py-16">
        <div className="container m-auto px-6">
          <div className="lg:flex justify-between items-center">
            <div className="lg:w-6/12 lg:p-0 p-7 order-1">
              <h3 className="text-4xl font-bold leading-tight mb-5 capitalize">
                APLICACIONES ADICIONALES
              </h3>
              <h1 className="py-4">Completa tu paquete</h1>
              <h4 className="py-4">
                Personaliza tu paquete de acuerdo a los requerimientos de tu
                empresa
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                  <ul>
                    <li>Inventarios</li>
                    <li>Nómina</li>
                    <li>Bodega de almacenes</li>
                    <li>Facturación en bloque</li>
                  </ul>
                </div>
                <div>
                  <ul>
                    <li>Órdenes de compra o servicio</li>
                    <li>Fuerza de ventas</li>
                    <li>Punto de venta (POS)</li>
                  </ul>
                </div>
              </div>
              <Button to="/" primary={true}>
                Contacta a un asesor
              </Button>
            </div>
            <div className="lg:w-5/12 order-2">
              <img src={imagePackage} />
            </div>
          </div>
        </div>
      </div>
    </ContainerPackage>
  )
}
