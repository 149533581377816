import React from "react"
import styled from "styled-components"
import imageBenefits from "../../assets/images/img_beneficios.png"
import settingsFast from "../../assets/images/Icon_fast.svg"
import settingsEasy from "../../assets/images/Icon_facil_de_usar.svg"
import settingsRobusto from "../../assets/images/Icon_facil_de_usar.svg"

const ContainerBenefits = styled.div`
  .benefits {
    text-align: center;
    h1 {
      margin: 40px auto;
      max-width: 800px;
      font-size: 34px;
      font-weight: bold;
      letter-spacing: 0px;
      color: #1b3055;
    }
  }
  .title-online {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  .title-company {
    max-width: 470px;
    color: #191851;
    font-size: 44px;
    font-weight: bold;
    line-height: 60px;
  }
  h4 {
    max-width: 455px;
    color: #576573;
    font-size: 20px;
    line-height: 35px;
  }
  .img-settings {
    margin-right: 40px;
    width: 76px;
    height: 110px;
  }
  .parrafe-benefit {
    color: #1b3055;
    font-weight: bold;
    font-size: 18px;
  }
  .subTitle-benefit {
    max-width: 500px;
    margin-top: 20px;
    line-height: 30px;
    font-weight: normal;
    color: #576573;
    font-size: 1rem;
  }
  ul {
    list-style-type: none;
  }
`
export default () => {
  return (
    <ContainerBenefits>
      <div className="benefits flex flex-col justify-center items-center">
        <h3 className="title-online">BENEFICIOS</h3>
        <h1>¿Por qué importa lo que hacemos, qué emociones genera?</h1>
        <div className="flex flex-wrap">
          <div className="w-full md:w-3/5">
            <img
              className="object-cover object-right w-full"
              src={imageBenefits}
            />
          </div>
          <div className="w-full md:w-2/5 flex justify-start items-start">
            <ul>
              <li className="py-10">
                <img align="left" className="img-settings" src={settingsFast} />
                <p className="parrafe-benefit">Robusto</p>
                <p className="subTitle-benefit">
                  Automatiza toda la gestión de tu empresa en un solo lugar,
                  desde la facturación hasta la liquidación de la nómina
                </p>
              </li>
              <li className="py-10">
                <img align="left" className="img-settings" src={settingsEasy} />
                <p className="parrafe-benefit">Rápido</p>
                <p className="subTitle-benefit">
                  Utiliza desde cualquier lugar, en cualquier dispositivo todas
                  las aplicaciones rápidamente
                </p>
              </li>
              <li className="py-10">
                <img
                  align="left"
                  className="img-settings"
                  src={settingsRobusto}
                />
                <p className="parrafe-benefit">Fácil de usar</p>
                <p className="subTitle-benefit">
                  No tienes que ser un experto en contabilidad para hacer todo a
                  la velocidad de la luz.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ContainerBenefits>
  )
}
