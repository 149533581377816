import React, { useState } from "react"
import Button from "./btn"
import styled from "styled-components"

let PriceTable = styled.div`
  .card-item {
    margin-top: 20px;
    width: 270px;
    height: 680px;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border: 1px solid
      ${props => (props.data.primary ? props.theme.secondary : "white")};
    padding: 20px;
    color: ${props => (props.data.invert ? "white" : props.theme.four)};
    background-color: ${props => (props.data.invert ? "#1465FF" : "white")};
    box-shadow: ${props =>
      props.data.primary ? "none" : "0px 9px 18px #0078bb40"};
    border-radius: 20px;
  }
  .card-info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    line-height: 1.5em;
  }
  .card-title {
    margin: 0 auto;
    margin-top: 5px;
    width: 150px;
    padding: 10px;
    font-size: 14px;
    color: #1465ff;
    font-weight: bold;
    background: #00deff;
    border-radius: 5px;
  }
  .card-subtitle {
    color: ${props =>
      props.data.invert ? props.theme.five : props.theme.third};
    font-size: 14px;
  }
  .container-price-count {
    border: 1px solid #07ade4;
    border-radius: 5px;
    padding: 5px 10px;
  }
  .card-price {
    margin-top: 60px;
    font-size: 44px;
    color: ${props => (props.data.invert ? "white" : props.theme.four)};
    font-weight: bold;
  }
  .sub-title-price > p {
    color: ${props => (props.data.invert ? "white" : props.theme.four)};
    font-weight: 600;
    font-size: 14px;
  }
  .card-button {
    margin: 30px 0 30px;
    width: 215px;
    height: 50px;
    padding: 10px;
    font-size: 14px;
  }
  ul,
  li {
    padding: 0;
    margin: 10px;
    text-align: left;
    list-style: none;
  }

  li {
    font-size: 14px;
    color: ${props => (props.data.invert ? "white" : props.theme.third)};
  }

  li:before {
    content: "✔";
    float: left;
    margin-left: -2em;
    color: ${props => (props.data.invert ? "#1465FF" : "#1465ff")};
    background: ${props => (props.data.invert ? "white" : "#00deff")};
    padding: 0px 4px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }
  @media (max-width: 480px) {
    .card-item {
      margin: 20px auto;
    }
  }
  .title-user {
    font-size: 14px;
    color: ${props => (props.data.invert ? "white" : props.theme.third)};
  }
  .count-box {
    margin: 0 auto;
    width: 30px;
    height: 30px;
    background: ${props => (props.data.invert ? "#0f51d0" : "#D2EDFF")};
    border-radius: 50px;
    color: ${props => (props.data.invert ? "white" : props.theme.four)};
    padding: 4px;
    font-weight: bold;
  }
  .button-icon-counter {
    font-size: 20px;
    padding: 0;
    margin: 4px 0 0 0;
    color: ${props => (props.data.invert ? "white" : props.theme.third)};
    border: none;
    background: none;
  }
`

export default ({ to, children, companies, ...props }) => {
  const [count, setCount] = useState(1)
  const total = props.data.price.split("mil")
  const titlePrice = props.data.price.split(total[0])
  return (
    <PriceTable {...props}>
      <div className="card-item text-center">
        <div className="card-info">
          <h2 className="card-title">{props.data.title}</h2>
          <p className="card-price mb-2">
            $ {total[0] * count}
            {titlePrice[1]}
          </p>
          <h3 className="card-subtitle mb-4">{props.data.month}</h3>
          <div className="container-price-count">
            <div className="flex">
              <div className="flex-1">
                <span className="title-user">Usuarios</span>
              </div>
              <div className="flex-1">
                <button
                  disabled={count === 1}
                  onClick={() => setCount(count - 1)}
                  className="button-icon-counter"
                >
                  -
                </button>
              </div>
              <div className="flex-1">
                <div className="count-box">{count}</div>
              </div>
              <div className="flex-1">
                <button
                  disabled={count === 10}
                  onClick={() => setCount(count + 1)}
                  className="button-icon-counter"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <ul>
                  <li>Inventarios</li>
                  <li>Nómina</li>
                  <li>Bodega de almacenes</li>
                  <li>Facturación en bloque</li>
                </ul>
              </div>
              <div className="sub-title-price">
                <p>Tipos de facturación</p>
              </div>
              <div>
                <ul>
                  <li>Órdenes de compra o servicio</li>
                  <li>Fuerza de ventas</li>
                  <li>Punto de venta (POS)</li>
                </ul>
              </div>
            </div>
          </div>
          <Button
            className="btn btn-primary btn-lg card-button"
            primary={!props.data.primary}
            invert={props.data.invert}
          >
            {props.data.button}
          </Button>
        </div>
      </div>
    </PriceTable>
  )
}
