import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PriceTable from "../component/price-table"

const ContainerPriceTable = styled.div`
  h1 {
    margin: 100px auto;
    margin-bottom: 10px;
    max-width: 800px;
    font-weight: bold;
    font-size: 44px;
    letter-spacing: 0px;
    color: #1b3055;
  }
  p {
    line-height: 30px;
    font-weight: normal;
    color: #576573;
    font-size: 20px;
  }
  @media (max-width: 480px) {
    h1 {
      font-size: 40px;
    }
    .container-price-grid {
      padding: 0 10px;
    }
  }
`

const ContainerButton = styled.button`
  font-weight: 500;
  background-color: ${props => {
    switch (props.data) {
      case "#empresas":
        return "#191851"
      case "#facturacion":
        return "#191851"
      case "#contador":
        return "#191851"
      case "#nomina":
        return "#191851"
    }
  }};
  color: ${props => {
    switch (props.data) {
      case "#empresas":
        return "white"
      case "#facturacion":
        return "white"
      case "#contador":
        return "white"
      case "#nomina":
        return "white"
      default:
        return "#707070"
    }
  }};
  @media (max-width: 480px) {
    border: ${props => {
      switch (props.data) {
        case "#empresas":
          return "none"
        case "#facturacion":
          return "none"
        case "#contador":
          return "none"
        case "#nomina":
          return "none"
        default:
          return "2px solid #707070;"
      }
    }};
  }
`
const ARRAY = [
  {
    id: 1,
    title: "EMPRENDEDORES",
    month: "Por empresa/mes*",
    price: "5mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
  {
    id: 2,
    title: "BÁSICO",
    month: "Por empresa/mes*",
    price: "15mil",
    button: "Comprar",
    primary: true,
    invert: true,
  },
  {
    id: 3,
    title: "PROFESIONAL",
    month: "Por empresa/mes*",
    price: "18mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
  {
    id: 4,
    title: "PYME",
    month: "Por empresa/mes*",
    price: "50mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
]

const ARRAY_EMPRESAS = [
  {
    id: 1,
    title: "EMPRENDEDORES",
    month: "Por empresa/mes*",
    price: "10mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
  {
    id: 2,
    title: "BÁSICO",
    month: "Por empresa/mes*",
    price: "25mil",
    button: "Comprar",
    primary: true,
    invert: true,
  },
  {
    id: 3,
    title: "PROFESIONAL",
    month: "Por empresa/mes*",
    price: "28mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
  {
    id: 4,
    title: "PYME",
    month: "Por empresa/mes*",
    price: "60mil",
    button: "Comprar",
    primary: true,
    invert: undefined,
  },
]

export default props => {
  const [companies, setCompanies] = useState(props.location.hash)

  const NEW_ARRAY = ["#empresas", "#contador"].includes(companies)
    ? ARRAY_EMPRESAS
    : ARRAY

  const handleClick = (event, value) => {
    event.preventDefault()
    setCompanies(value)
  }

  useEffect(() => {
    setCompanies(props.location.hash)
  }, [props.location.hash])

  return (
    <ContainerPriceTable
      {...props}
      companies={companies}
      className="w-full max-w-7xl mx-auto"
    >
      <div className="w-full md:text-center px-4 pt-6 md:py-6">
        <h1>Un software empresarial integro y robusto</h1>
        <p className="hidden md:block">
          Los requerimientos contables de tu compañía en un mismo lugar
        </p>
      </div>
      <div className="container-price-grid grid gap-2 mb-8 mt-12 grid-cols-2 xl:flex xl:max-w-xl xl:mx-auto">
        <ContainerButton
          type="button"
          data={companies === "#empresas" ? companies : ""}
          onClick={event => handleClick(event, "#empresas")}
          className="button-software text-white rounded-md md:px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
        >
          Facturación
        </ContainerButton>
        <ContainerButton
          type="button"
          data={companies === "#facturacion" ? companies : ""}
          onClick={event => handleClick(event, "#facturacion")}
          className="button-software text-white rounded-md md:px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
        >
          Nómina
        </ContainerButton>
        <ContainerButton
          type="button"
          data={companies === "#contador" ? companies : ""}
          onClick={event => handleClick(event, "#contador")}
          className="button-software text-white rounded-md md:px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
        >
          Contador
        </ContainerButton>
        <ContainerButton
          type="button"
          data={companies === "#nomina" ? companies : ""}
          onClick={event => handleClick(event, "#nomina")}
          className="button-software text-white rounded-md md:px-4 py-2 m-2 transition duration-500 ease select-none focus:outline-none focus:shadow-outline"
        >
          Empresariales
        </ContainerButton>
      </div>
      <div>
        <div className="flex flex-wrap items-center justify-center py-4 pt-0">
          {NEW_ARRAY.map(value => (
            <div
              key={value.id}
              className="w-full p-4 md:w-1/2 lg:w-1/4 plan-card"
            >
              <PriceTable data={value} companies={companies} />
            </div>
          ))}
        </div>
      </div>
    </ContainerPriceTable>
  )
}
