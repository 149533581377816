import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Form from "../component/form"
import styled from "styled-components"
import Button from "../component/btn"
import imageRegister from "../../assets/images/register_home.png"

const ContainerRegister = styled.div`
  padding: 40px 215px;
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    max-width: 500px;
    color: #191851;
    font-size: 34px;
    font-weight: bold;
    line-height: 50px;
  }
  img {
    width: 70%;
  }
  .container-register {
    margin-top: 80px;
    color: black;
    max-width: 520px;
    max-height: 670px;
    background: #ffffff;
    box-shadow: 0px 9px 18px #0078bb40;
    border-radius: 20px;
    opacity: 1;
  }
  .container-form {
    box-shadow: 0px 18px 36px #0078bb1a;
    border-radius: 10px;
    opacity: 1;
  }
  @media (max-width: 480px) {
    padding: 0;
    .container-register {
      box-shadow: none;
    }
    img {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
`
export default () => {
  return (
    <ContainerRegister>
      <div className="w-full overflow-hidden">
        <div className="md:flex w-full">
          <div className="hidden md:block w-1/2 py-10 px-10">
            <div className="text-left mb-10">
              <h3>DEMO</h3>
              <h1>
                Haz una prueba gratis por 15 días, no necesitas tarjeta de
                crédito.
              </h1>
            </div>
            <img src={imageRegister} />
          </div>
          <div className="w-full md:w-1/2 py-10 px-5 md:px-10 container-form">
            <Form />
          </div>
        </div>
      </div>
    </ContainerRegister>
  )
}
