import React from "react"
import Button from "./btn"
import styled from "styled-components"

const ContainerFormReferreals = styled.div`
  padding: 70px;
  .button-submit {
    margin-top: 20px;
    width: 100%;
  }
  .check-condition {
    color: #576573;
    margin: 0 10px;
  }
  label {
    margin-bottom: 20px;
    font-size: 14px;
    color: #1b3055;
  }
  input {
    padding: 25px;
    border: 1px solid #07ade459;
    border-radius: 5px;
    opacity: 1;

    &::placeholder {
      color: #576573;
    }
  }
  @media (max-width: 480px) {
    padding: 15px;
  }
`

export default props => (
  <ContainerFormReferreals>
    {/* <Form>
      <FormGroup>
        <Label for="registerName">Nombre y apellidos</Label>
        <Input
          type="text"
          name="text"
          id="registerName"
          placeholder="Nombre y apellidos"
        />
      </FormGroup>
      <FormGroup>
        <Label for="registerEmail">Correo electrónico</Label>
        <Input
          type="email"
          name="email"
          id="registerEmail"
          placeholder="Correo"
        />
      </FormGroup>
      <FormGroup>
        <Label for="registerPhone">Celular</Label>
        <Input
          type="text"
          name="phone"
          id="registerPhone"
          placeholder="Celular"
        />
      </FormGroup>
      <FormGroup>
        <Label for="registerPhone">Dirección</Label>
        <Input
          type="text"
          name="address"
          id="registerPhone"
          placeholder="Dirección"
        />
      </FormGroup>
      <FormGroup>
        <Label for="registerPhone">Código postal</Label>
        <Input
          type="text"
          name="code postal"
          id="registerPhone"
          placeholder="Código postal"
        />
      </FormGroup>
      <FormGroup>
        <Label for="registerPhone">Teléfono/celular</Label>
        <Input
          type="text"
          name="phone"
          id="registerPhone"
          placeholder="Teléfono/celular"
        />
      </FormGroup>
      <Button className="button-submit btn btn-primary btn-lg" primary={true}>
        Enviar
      </Button>
    </Form> */}
  </ContainerFormReferreals>
)
