import React from "react"
import styled from "styled-components"
import PriceCard from "../component/price-single"

const ContainerDirectory = styled.div`
  margin: 40px 0 40px 0;
  background-color: #191851;
  h3 {
    color: white;
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h4 {
    margin-top: 50px;
    color: white;
    font-size: 20px;
    font-weight: 300;
    line-height: 35px;
  }
  img {
    margin-right: 180px;
    width: 30%;
  }
  .link-blue {
    color: #1465ff;
    font-size: 16px;
    font-weight: 500;
  }
`
const VALUE = {
  id: 1,
  title: "FACTURACIÓN",
  month: "Por empresa/mes*",
  price: "5mil",
  button: "Registrarme",
  primary: false,
  invert: undefined,
  titleCard: "Tipos de facturación",
  list: ["Electrónica DIAN", "Por computador", "POS", "Preimpresa"],
}

export default () => {
  return (
    <ContainerDirectory>
      <div>
        <div>
          <div>
            <PriceCard data={VALUE} />
          </div>
          <div>
            <h3>Directorio VIP contadores Claus</h3>
            <h4>
              A diario recibimos solicitudes de empresarios que usan Claus
              necesitando un contador y ahí es donde entras tú. Registrate en
              nuestro directorio VIP de contadores que usan Claus y te
              referiremos empresarios que se encuentren cerca de ti.
            </h4>
            <div className="link-blue">Ver más</div>
          </div>
        </div>
      </div>
    </ContainerDirectory>
  )
}
