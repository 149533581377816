import React from "react"
import Link from "../component/link"
import iconOne from "../../assets/images/icon1.png"
import iconTwo from "../../assets/images/icon2.png"
import iconThree from "../../assets/images/icon3.png"
import iconFour from "../../assets/images/icon4.png"
import iconFive from "../../assets/images/icon5.png"
import iconSix from "../../assets/images/icon6.png"

const PRODUCTOS = [
  { name: iconOne, url: "/product" },
  { name: iconTwo, url: "/product" },
  { name: iconThree, url: "/product" },
  { name: iconFour, url: "/product" },
  { name: iconFive, url: "/product" },
  { name: iconSix, url: "/product" },
]
const PRODUCTOS_SOFTWARE = [
  { name: "Para contadores", url: "/product" },
  { name: "Para pymes", url: "/product" },
  { name: "Para emprendedores", url: "/product" },
]

const PRODUCTOS_COMERCIO = [
  { name: "Para restaurantes", url: "/product" },
  { name: "Para comercios", url: "/product" },
  { name: " Para abogados.", url: "/product" },
]

const MenuProducts = () => {
  return (
    <div className="border-top-menu absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-2xl sm:px-0 lg:ml-0 ">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          <div className="ml-4">
            <p className="text-base font-medium text-gray-900">
              Tipos de software
            </p>
          </div>

          <div className="-ml-8 grid grid-cols-3 gap-2">
            {PRODUCTOS &&
              PRODUCTOS.map(({ url, name }, i) => {
                return (
                  <div key={i}>
                    <Link
                      to={url}
                      className="flex items-start rounded-lg hover:bg-gray-50"
                    >
                      <img className="object-contain w-full" src={name} />
                    </Link>
                  </div>
                )
              })}
          </div>
        </div>

        <div className="px-5 py-5 bg-white space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
          <div className="grid grid-cols-2 gap-12">
            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">
                Tipos de software
              </p>
              <ul>
                {PRODUCTOS_SOFTWARE &&
                  PRODUCTOS_SOFTWARE.map(({ url, name }, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={url}
                          className="-m-3 p-3 mt-1 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {name}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>

            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">
                Para comercios
              </p>
              <ul>
                {PRODUCTOS_COMERCIO &&
                  PRODUCTOS_COMERCIO.map(({ url, name }, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={url}
                          className="-m-3 p-3 mt-1 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                        >
                          {name}
                        </Link>
                      </li>
                    )
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default MenuProducts
