import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import FormReferreals from "../component/form-referreals"
import styled from "styled-components"
import imageRegister from "../../assets/images/referreal-register.png"

const ContainerReferrealsRegister = styled.div`
  padding: 40px 215px;
  background-color: #f8f8f8;
  h3 {
    color: #14a0ff;
    font-size: 1rem;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    max-width: 560px;
    color: #191851;
    font-size: 34px;
    font-weight: bold;
    line-height: 60px;
  }
  img {
    width: 70%;
  }
  .container-register {
    margin-top: 80px;
    color: black;
    max-width: 520px;
    background: #ffffff;
    box-shadow: 0px 9px 18px #0078bb40;
    border-radius: 20px;
    opacity: 1;
  }
  @media (max-width: 480px) {
    padding: 0;
    .container-register {
      box-shadow: none;
    }
    img {
      display: none;
    }
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
`
export default () => {
  return (
    <ContainerReferrealsRegister>
      <div>
        <div>
          <div>
            <h3 >REGISTRO</h3>
            <h1 >Regístrate en nuestroDirectorio VIP</h1>
            <div>
              <img src={imageRegister} />
            </div>
          </div>
          <div>
            <div className="container-register">
              <FormReferreals />
            </div>
          </div>
        </div>
      </div>
    </ContainerReferrealsRegister>
  )
}
