import React from "react"
import { FaWhatsapp } from "react-icons/fa"
import styled from "styled-components"

const ContainerButtonWhatsApp = styled.div`
  .contenedor {
    width: 90px;
    height: 240px;
    position: fixed;
    right: 80px;
    bottom: 50px;
  }
  .botonF1 {
    width: 68px;
    height: 68px;
    background: #00e09a;
    box-shadow: 0px 3px 6px #0078bb66;
    border: 2px solid #ffffff;
    opacity: 1;
    border-radius: 100%;
    right: 0;
    bottom: 0;
    position: absolute;
    outline: none;
    color: #fff;
    font-size: 36px;
    transition: 0.3s;
  }
  span {
    transition: 0.5s;
    color: #191851;
  }
  .botonF1:hover span {
    transform: rotate(360deg);
  }
  .botonF1:active {
    transform: scale(1.1);
  }
  @media (max-width: 480px) {
    .contenedor {
      display: none;
    }
  }
  .center-icon {
    margin: 0 auto;
  }
`

const ButtonWhatsApp = () => {
  return (
    <ContainerButtonWhatsApp>
      <div className="contenedor">
        <button className="botonF1">
          <span>
            <FaWhatsapp className="center-icon" />
          </span>
        </button>
      </div>
    </ContainerButtonWhatsApp>
  )
}

export default ButtonWhatsApp
