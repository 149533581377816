import React from "react"
import styled from "styled-components"
import testimonial from "../../assets/images/54.png"
import testimonialResponsive from "../../assets/images/54-mobiles.png"

const ContainerTestimonial = styled.div`
  background-image: url(${testimonial});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  @media (max-width: 480px) {
    background-image: url(${testimonialResponsive});
  }
`
export default () => {
  return <ContainerTestimonial className="w-full"></ContainerTestimonial>
}
