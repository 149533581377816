import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/component/seo"
import ContainerHome from "../components/home/container-home"
import ContainerBenefist from "../components/home/container-benefits"
import ContainerTestimonial from "../components/home/container-testimonial"
import ContainerCharacteristics from "../components/home/container-characteristics"
import ContainerBanners from "../components/home/container-banners"
import ContainerPrice from "../components/home/container-price"
import ContainerRegister from "../components/home/container-register"
import ButtonWhatsApp from "../components/component/button-whats-app"

export default () => (
  <Layout>
    <ButtonWhatsApp />
    <SEO title="Home" />
    <ContainerHome />
    <ContainerBenefist />
    <ContainerTestimonial />
    <ContainerCharacteristics />
    <ContainerBanners />
    <ContainerPrice />
    <ContainerRegister />
  </Layout>
)
