import React from "react"
import Link from "../component/link"

const MenuMeans = () => {
  return (
    <div className="border-top-menu absolute z-10 transform mt-3 px-2 w-screen max-w-md sm:px-0">
      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
          <div className="ml-4">
            <p className="-m-3 p-3 text-base font-medium text-gray-900">
              Blog:{" "}
            </p>
            <ul>
              <li>
                <Link
                  to="/blog"
                  className="-m-3 p-3 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                >
                  ¿Cómo crear una empresa?
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="-m-3 p-3 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                >
                  Marketing para pymes
                </Link>
              </li>
            </ul>
          </div>

          <div className="ml-4">
            <p className="-m-3 p-3 text-base font-medium text-gray-900">
              Formatos de excel
            </p>
            <ul>
              <li>
                <Link
                  to="/blog"
                  className="-m-3 p-3 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                >
                  Nómina
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="-m-3 p-3 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                >
                  Impuestos
                </Link>
              </li>
              <li>
                <Link
                  to="/blog"
                  className="-m-3 p-3 mt-1 text-sm text-gray-500 flex items-start rounded-lg hover:bg-gray-50"
                >
                  Flujo de caja
                </Link>
              </li>
            </ul>
          </div>

          <Link
            to="/"
            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">
                Centro de ayuda
              </p>
            </div>
          </Link>
          <Link
            to="/"
            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">Videos</p>
            </div>
          </Link>
          <Link
            to="/"
            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">E-books</p>
            </div>
          </Link>
          <Link
            to="/"
            className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
          >
            <div className="ml-4">
              <p className="text-base font-medium text-gray-900">Webinars</p>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}
export default MenuMeans
