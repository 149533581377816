import React, { useState, useEffect } from "react"
import { Transition } from "@headlessui/react"
import MenuProducts from "./menuProducts"
import MenuMeans from "./menuMeans"
import MenuPrice from "./menuPrice"
import Link from "../component/link"
import logo from "../../assets/images/logo.svg"
import styled from "styled-components"

const Aliados = [
  { name: "Referidos para contadores", url: "/referreals" },
  { name: "Referidos para clientes", url: "/referreals" },
  { name: "Referidos para personas externas", url: "/referreals" },
  { name: "Otros aliados", url: "/referreals" },
]

const Precios = [
  { name: "Empresariales", url: "/price#empresas" },
  { name: "Facturacion electronica", url: "/price#facturacion" },
  { name: "Claus contador", url: "/price#contador" },
  { name: "Nómina", url: "/price#nomina" },
]

const ContainerHeader = styled.div`
  .border-top-menu {
    border-top: 5px solid #1465ff;
  }
`

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenTwo, setIsOpenTwo] = useState(false)
  const [isOpenThree, setIsOpenThree] = useState(false)
  const [isOpenFour, setIsOpenFour] = useState(false)
  const [isOpenMobile, setIsOpenMobile] = useState(false)

  const modalOne = () => {
    setIsOpen(!isOpen)
    setIsOpenTwo(false)
    setIsOpenThree(false)
    setIsOpenFour(false)
  }

  const modalTwo = () => {
    setIsOpen(false)
    setIsOpenTwo(!isOpenTwo)
    setIsOpenThree(false)
    setIsOpenFour(false)
  }

  const modalThree = () => {
    setIsOpen(false)
    setIsOpenTwo(false)
    setIsOpenThree(!isOpenThree)
    setIsOpenFour(false)
  }

  const modalFour = () => {
    setIsOpen(false)
    setIsOpenTwo(false)
    setIsOpenThree(false)
    setIsOpenFour(!isOpenFour)
  }

  const [showScroll, setShowScroll] = useState(true)

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop)
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop)
    }
  })

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset <= 400) {
      setShowScroll(true)
    } else if (
      showScroll &&
      window.pageYOffset >= 400 &&
      window.innerWidth > 400
    ) {
      setShowScroll(false)
    }
  }

  return (
    <ContainerHeader>
      <div className="fixed w-full bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start md:w-0 md:flex-1">
              <Link to="/">
                <span className="sr-only">Claus</span>
                <img className="h-8 w-auto sm:h-10" src={logo} alt="Claus" />
              </Link>
            </div>
            <div className="-mr-2 -my-2 xl:hidden">
              <button
                onClick={() => setIsOpenMobile(!isOpenMobile)}
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Open menu</span>
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
            <nav className="hidden xl:flex space-x-10">
              <div className="relative">
                <button
                  onClick={() => modalOne()}
                  type="button"
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Productos</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
                <Transition
                  show={isOpen}
                  enter="transition ease-out duration-180 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {ref => (
                    <div ref={ref}>
                      <MenuProducts />
                    </div>
                  )}
                </Transition>
              </div>

              <div className="relative">
                <button
                  onClick={() => modalTwo()}
                  type="button"
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Recursos</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <Transition
                  show={isOpenTwo}
                  enter="transition ease-out duration-180 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {ref => (
                    <div ref={ref}>
                      <MenuMeans />
                    </div>
                  )}
                </Transition>
              </div>

              <div className="relative">
                <button
                  onClick={() => modalThree()}
                  type="button"
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Aliados</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <Transition
                  show={isOpenThree}
                  enter="transition ease-out duration-180 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {ref => (
                    <div ref={ref}>
                      <MenuPrice data={Aliados} />
                    </div>
                  )}
                </Transition>
              </div>

              <div className="relative">
                <button
                  onClick={() => modalFour()}
                  type="button"
                  className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span>Precios</span>
                  <svg
                    className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                <Transition
                  show={isOpenFour}
                  enter="transition ease-out duration-180 transform"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-150 transform"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {ref => (
                    <div ref={ref}>
                      <MenuPrice data={Precios} />
                    </div>
                  )}
                </Transition>
              </div>

              <Link
                to="/register-companies"
                className="text-base font-medium text-gray-500 hover:text-blue-500"
              >
                Registrar empresa
              </Link>
            </nav>
            <div className="hidden xl:flex items-center justify-between xl:flex-1 xl:w-0">
              <Link
                to="/login"
                className="whitespace-nowrap text-base font-medium text-blue-600 hover:text-blue-500"
              >
                Ingresar
              </Link>
              <Link
                to="/demo"
                className="ml-8 whitespace-nowrap inline-flex items-center text-blue-600 justify-center px-6 py-3 border-2 shadow-sm text-base font-medium rounded-full border-solid border-blue-600"
                style={{
                  backgroundColor: showScroll ? "transparent" : "#1465FF",
                  color: showScroll ? "#1465FF" : "white",
                }}
              >
                Solicita un Demo
              </Link>
            </div>
          </div>
        </div>

        <Transition
          show={isOpenMobile}
          enter="transition ease-out duration-180 transform"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-150 transform"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {ref => (
            <div
              ref={ref}
              className="border-top-menu absolute top-0 inset-x-0 p-2 transition transform origin-top-right xl:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img className="h-8 w-auto" src={logo} alt="Workflow" />
                    </div>
                    <div className="-mr-2">
                      <button
                        onClick={() => setIsOpenMobile(!isOpenMobile)}
                        type="button"
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      >
                        <span className="sr-only">Close menu</span>
                        <svg
                          className="h-6 w-6"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      <div className="relative">
                        <button
                          onClick={() => modalOne()}
                          type="button"
                          className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <span>Productos</span>
                          <svg
                            className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>
                        <Transition
                          show={isOpen}
                          enter="transition ease-out duration-180 transform"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-150 transform"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          {ref => (
                            <div ref={ref}>
                              <MenuProducts />
                            </div>
                          )}
                        </Transition>
                      </div>

                      <div className="relative">
                        <button
                          onClick={() => modalTwo()}
                          type="button"
                          className="group bg-white rounded-md text-gray-500 inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <span>Recursos</span>
                          <svg
                            className="ml-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </button>

                        <Transition
                          show={isOpenTwo}
                          enter="transition ease-out duration-180 transform"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-150 transform"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          {ref => (
                            <div ref={ref}>
                              <MenuMeans />
                            </div>
                          )}
                        </Transition>
                      </div>




                      
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div>
                    <Link
                      to="/"
                      className="w-full flex items-center justify-center px-4 py-2 border-2 border-solid rounded-full shadow-sm text-base font-medium text-blue-700 border-blue-700"
                    >
                      Ingresar
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </ContainerHeader>
  )
}

export default Header
