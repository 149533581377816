import React from "react"
import Link from "./link"
import styled from "styled-components"

const handleColorType = props => {
  if (props.primary) {
    return "white"
  }

  if (props.invert) {
    return props.theme.primary
  }

  return props.theme.primary
}

const handleColor = props => {
  if (props.primary) {
    return props.theme.primary
  }

  if (props.invert) {
    return "white"
  }

  return "white"
}

const Button = styled.div`
  color: ${props => handleColorType(props)};
  border: 2px solid ${props => handleColorType(props)};
  padding: 16px;
  transition-duration: 0.25s;
  background-color: ${props => handleColor(props)};
  border-radius: 28px;
  opacity: 1;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  width: 100%;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 1rem 2.5rem rgba(22, 28, 45, 0.1),
      0 0.5rem 1rem -0.75rem rgba(22, 28, 45, 0.1);
  }
`

export default ({ to, children, ...props }) => (
  <Link to={to}>
    <Button {...props} className="py-2 mt-2">
      {children}
    </Button>
  </Link>
)
