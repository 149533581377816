import React from "react"
import Button from "./btn"
import styled from "styled-components"

let Price = styled.div`
  .card-item {
    border: 1px solid
      ${props => (props.data.primary ? props.theme.secondary : "white")};
    color: ${props => (props.data.invert ? "white" : "#1B3055")};
    background-color: ${props => (props.data.invert ? "#1465FF" : "white")};
    box-shadow: ${props =>
      props.data.primary ? "none" : "0px 9px 18px #0078bb40"};
    border-radius: 20px;
    transition: 1s;

    &:hover {
      border: 1px solid
        ${props => (props.data.primary ? "white" : props.theme.secondary)};
      box-shadow: 0px 9px 18px #0078bb40;
    }
  }
  .card-title {
    margin: 0 auto;
    margin-top: 5px;
    width: 150px;
    padding: 10px;
    font-size: 14px;
    color: #1465ff;
    font-weight: bold;
    background: #00deff;
    border-radius: 5px;
  }
  .card-subtitle {
    margin-top: 30px;
    color: ${props => (props.data.invert ? "#C2EAFF" : "#576573")};
    font-size: 14px;
  }
  .card-price {
    font-size: 40px;
    color: ${props => (props.data.invert ? "white" : "#1B3055")};
    font-weight: bold;
  }
`

export default ({ to, children, ...props }) => (
  <Price {...props}>
    <div className="card-item">
      <label className="flex flex-col group cursor-pointer">
        <div className="w-full text-center px-4 py-6 card-section-1">
          <h3 className="card-title">{props.data.title}</h3>
          <p className="card-subtitle">{props.data.month}</p>
          <p className="card-price">$ {props.data.price}</p>
        </div>
        <div className="flex flex-col items-center justify-center w-full h-full py-4">
          <Button
            className="card-button"
            primary={!props.data.primary}
            invert={props.data.invert}
          >
            {props.data.button}
          </Button>
        </div>
      </label>
    </div>
  </Price>
)
