import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/component/seo"
import ContainerReferreals from "../components/referrals/container-referrals"
import ContainerDirectory from "../components/referrals/container-directory"
import ContainerPackage from "../components/referrals/container-package"
import ContainerReferrealsRegister from "../components/referrals/container-referreals-register"

export default () => (
  <Layout>
    <SEO title="Referreals" />
    <ContainerReferreals />
    <ContainerDirectory />
    <ContainerPackage />
    <ContainerReferrealsRegister />
  </Layout>
)
