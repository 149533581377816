import React from "react"

import SEO from "../components/component/seo"
import ContainerCongratulations from '../components/checkout/congratulations'

export default () => (
  <>
    <SEO title="Home" />
    <ContainerCongratulations />
  </>
)
