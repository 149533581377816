import React from "react"
import styled from "styled-components"
import loco1 from "../../assets/images/plan/grupo11.png"
import loco2 from "../../assets/images/plan/grupo12.png"
import loco3 from "../../assets/images/plan/grupo13.png"
import loco4 from "../../assets/images/plan/grupo15.png"

const ARRAY = [
  {
    key: 1,
    img: loco1,
    title: "Facturación electrónica",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 2,
    img: loco2,
    title: "Conciliación bancaria",
    subTitle: "Automática y precisa.",
    link: "",
  },
  {
    key: 3,
    img: loco3,
    title: "Impuestos",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
  {
    key: 4,
    img: loco4,
    title: "Centros de costos",
    subTitle: "Simple, rápida y autorizada por la DIAN",
    link: "",
  },
]

const ContainerPlan = styled.div`
  background-color: #191851;
  h3 {
    text-align: center;
    color: #14a0ff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.7px;
  }
  h1 {
    text-align: center;
    color: white;
    font-size: 34px;
    font-weight: bold;
    line-height: 60px;
  }
  ul li {
    margin-top: 100px;
    list-style-type: none;
  }
  img {
    width: 100%;
  }
  .container-characteristics {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0078bb33;
    border-radius: 50px;
    opacity: 1;
    width: 76px !important;
  }
  .characteristics-list {
    color: white;
    margin-top: 40px;
    font-size: 18px;
    font-weight: bold;
  }
  .characteristics-subtitle {
    max-width: 220px;
    color: white;
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 40px;
  }
  .characteristics-link {
    font-size: 16px;
    color: #1465ff;
    font-weight: bold;
  }
`
export default () => {
  return (
    <ContainerPlan className="max-w-full w-full my-28 py-28">
      <div className="max-w-6xl mx-auto lg:p-0 p-7">
        <div className="container-plan">
          <h3>APLICACIONES</h3>
          <h1>Todos los planes incluyen</h1>
        </div>
        <div className="grid gap-6 mb-8 md:grid-cols-2 xl:grid-cols-4 mt-12">
          {ARRAY.map(({ key, img, title, subTitle, link }) => (
            <div key={key}>
              <img className="container-characteristics" src={img} />
                <p className="characteristics-list">{title}</p>
                <p className="characteristics-subtitle">{subTitle}</p>
                <a className="characteristics-link">Ver más +</a>
            </div>
          ))}
        </div>
      </div>
    </ContainerPlan>
  )
}
