import React from "react"
import Button from "./btn"
import styled from "styled-components"

let PriceCard = styled.div`
  .card-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    width: 270px;
    text-align: center;
    box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border: 1px solid
      ${props => (props.data.primary ? props.theme.secondary : "white")};
    padding: 20px;
    color: ${props => (props.data.invert ? "white" : "#1B3055")};
    background-color: ${props => (props.data.invert ? "#1465FF" : "white")};
    box-shadow: ${props =>
      props.data.primary ? "none" : "0px 9px 18px #0078bb40"};
    border-radius: 20px;
  }
  .card-info {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    line-height: 1.5em;
  }
  .card-title {
    margin: 0 auto;
    margin-top: 40px;
    width: 150px;
    padding: 10px;
    font-size: 14px;
    color: #1465ff;
    font-weight: bold;
    background: #00deff;
    border-radius: 5px;
    letter-spacing: 0.7px;
    opacity: 0.5;
  }
  .card-subtitle {
    font-weight: normal;
    color: ${props => (props.data.invert ? "#C2EAFF" : "#576573")};
    font-size: 14px;
  }
  .card-price {
    margin-top: 30px;
    font-size: 44px;
    color: ${props => (props.data.invert ? "white" : "#1B3055")};
    font-weight: bold;
  }
  .card-button {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 215px;
    height: 52px;
    padding: 10px;
    font-size: 14px;
  }
  .sub-title-price > p {
    margin-top: 30px;
    color: ${props => (props.data.invert ? "white" : props.theme.four)};
    font-weight: 600;
    font-size: 14px;
  }
  ul,
  li {
    padding: 0;
    margin: 20px 10px;
    text-align: left;
    list-style: none;
  }

  li {
    font-size: 14px;
    color: ${props => (props.data.invert ? "white" : props.theme.third)};
    font-weight: 500;
  }
  li:before {
    content: "✔";
    float: left;
    margin-left: -2.4em;
    color: ${props => (props.data.invert ? "#1465FF" : "#1465ff")};
    background: ${props => (props.data.invert ? "white" : "#00deff")};
    padding: 0px 4px;
    border-radius: 50%;
    width: 22px;
    height: 22px;
  }
  @media (max-width: 480px) {
    .card-item {
      margin: 20px auto;
    }
  }
`

export default ({ to, children, ...props }) => (
  <PriceCard {...props}>
    <div className="card-item">
      <div className="card-info">
        <h2 className="card-title">{props.data.title}</h2>
        <p className="card-price">$ {props.data.price}</p>
        <h3 className="card-subtitle">{props.data.month}</h3>
        <div>
          <div>
            <div className="sub-title-price">
              <p>{props.data.titleCard}</p>
            </div>
            <div>
              <ul>
                {props.data.list.map(value => (
                  <li key={value}>{value}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <Button
          className="btn btn-primary btn-lg card-button"
          primary={!props.data.primary}
          invert={props.data.invert}
        >
          {props.data.button}
        </Button>
      </div>
    </div>
  </PriceCard>
)
