import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import PriceCard from "../component/price-single"
import imageReferreals from "../../assets/images/referreals-home.png"

const ContainerPackage = styled.div`
  padding: 60px 215px;
  h3 {
    max-width: 460px;
    color: #191851;
    font-size: 34px;
    font-weight: bold;
    line-height: 60px;
  }
  h4 {
    max-width: 460px;
    font-weight: 400;
    color: #576573;
    font-size: 20px;
    line-height: 35px;
  }
  .container-referreals-image {
    background-image: url(${imageReferreals});
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
  }
  .link-blue {
    color: #1465ff;
    font-size: 16px;
    font-weight: 500;
  }
  .container-plan {
    margin-top: 150px;
  }
  @media (max-width: 480px) {
    padding: 0;
  }
  @media (max-width: 1024px) {
    padding: 20px;
  }
`

const VALUE = {
  id: 1,
  title: "FACTURACIÓN",
  month: "Por empresa/mes*",
  price: "5mil",
  button: "Registrarme",
  primary: false,
  invert: undefined,
  titleCard: "Tipos de facturación",
  list: ["Electrónica DIAN", "Por computador", "POS", "Preimpresa"],
}

export default () => {
  return (
    <ContainerPackage>
      <div>
        <div>
          <div>
            <div className="container-plan">
              <h3>Plan contador GRATIS</h3>
              <h4>
                Por cada empresario que nos refieras y adquiera cualquiera de
                nuestros planes empresariales, te regalaremos un plan contador
                gratis.
              </h4>
            </div>
            <div className="link-blue">Ver más</div>
          </div>
          <div>
            <div className="container-referreals-image">
              <PriceCard data={VALUE} />
            </div>
          </div>
        </div>
      </div>
    </ContainerPackage>
  )
}
